import Global from "./global";

class Archive{
    constructor(){
        this.archive = document.querySelector('.post-archive') || false;
        this.glob = new Global();
        if (this.archive && !this.archive.classList.contains('taxonomy_de')) !this.archive.querySelector('.show_next__btn') || this.archive.querySelector('.show_next__btn').addEventListener('click', () => this.ajaxPost(this.archive.querySelector('.post-archive__posts .posts')));
    } 

    ajaxPost(element) {
        const http = new XMLHttpRequest(),
            self = element,
            count = element.dataset.count,
            url = AJAXURL,
            data = {
                action: element.dataset.type == 'post' ? 'ajax_post' : 'ajax_packages',
                count: element.dataset.count,
                night: element.dataset.category,
                type: element.dataset.type,
                taxonomy: element.dataset.taxonomy
            },
            params = this.glob.objectDecapit(data);

        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => this.archive.querySelector('.show_next__btn').classList.add('active');;
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                let posts = JSON.parse(http.response);
                if (count == 0) {
                    element.querySelector('.archive-curses__query__posts').innerHTML = '';
                    element.dataset.count = 0;
                }
                if (posts.post) posts.post.forEach(item => element.append(element.dataset.type != 'post' ? this.post_packages(item) : this.post(item)));
                else element.querySelector('.archive-curses__query__posts').innerHTML = `<h2>${posts.no_found}</h2>`;
                let first = this.archive.querySelector('.show_next h3 .first'),
                    last_number = this.archive.querySelector('.show_next h3 .last'),
                    first_number = Number(first.innerText),
                    ps_count = posts.count === undefined ? 0 : posts.count;

                first.innerText = Number(count) === 0 ? ps_count : first_number + Number(ps_count);
                last_number.innerText = posts.all || 0;
                let procent = (Number(first.innerText) / posts.all) * 100 || 0;
                this.archive.querySelector('.show_next__bars span').style.width = procent + '%';
                if (!posts.pagination) {
                    this.archive.querySelector('.show_next__btn').classList.add('hidden');
                } else {
                    this.archive.querySelector('.show_next__btn').classList.remove('hidden');
                }
                element.dataset.count = Number(element.dataset.count) + 9;
            }
        };
        http.onloadend = () => this.archive.querySelector('.show_next__btn').classList.remove('active');
        http.send(params);
    }

    post_packages(element) {
        let div = document.createElement('div'),
            prices = '',
            lists = [];
        div.classList.add('show_packages__box');

        if (element.price.price != 0) {
            prices = `<span class="price">
                <p>From</p>
                <h4 class="price_regular ${element.price.price_promoto ? 'price_regular--despile' : ''}">£${element.price.price}</h4>
                ${element.price.price_promoto ? `<h4 class="price_promoto">£${element.price.price_promoto}</h4>` : ''}
            </span>`
        }
        if (element.lists) element.lists.forEach(item => lists.push(`<li><a href="${item.link}">${item.title}</a></li>`));
        div.innerHTML = `
            <a class="image" href="${element.link}">
                <img  src="${element.image}" alt="${element.title_image}">
                ${prices}
            </a>
            ${element.category ? `<h4>${element.category.join(', ')}</h4>` : ''}
            <h3><a href="${element.link}">${element.title}</a></h3>
            ${lists && lists.length != 0 ? `<ul>${lists.join('')}</ul>` : ''}
        `;
        return div;
    }

    post(element){
        let div = document.createElement('div');
        div.classList.add('box');
        div.innerHTML = `<a class="image" href="${element.link}"><img src="${element.image}" alt="${element.title_image}"></a>${element.categories}<h3><a href="${element.link}">${element.title}</a></h3>`;
        return div;
    }

}

export default Archive;