import global from './global';

class Header{
    constructor(){
        this.glob = new global();
        this.main = document.getElementById('main_section');
        this.header = document.body.querySelector('.header');
        this.hamburger = document.querySelector('.header__hamburger');
        this.btn_search = document.querySelector('.header__btn-search');
        this.search = document.querySelector('.header__search');
        
        !this.search || this.glob.sectionSearch(this.search);
        !this.btn_search || this.btn_search.addEventListener('click', e => {
            e.preventDefault();
            if (this.header.querySelector('.header__nav').classList.contains('active')) this.hamburger.querySelector('button').click();
            this.header.querySelector('.header__fixed').classList.toggle('show_search');
        })
        if (this.main.dataset.header == 'header--white') this.header.classList.add('header--white');
        else {this.header.classList.remove('header--white')};
        ['DOMContentLoaded', 'scroll'].forEach(element => document.addEventListener(element, () => {
            if (window.scrollY > 10) this.header.classList.add('active');
            else this.header.classList.remove('active');
        }));

        this.mobileNav(this.hamburger);

        if (window.innerWidth < 991){
            this.menuMobile();
            setInterval(() => {
                this.header.querySelector('.header__nav').style.height = `${window.innerHeight}px`;
            }, 100);

        } 
    }
    mobileNav(el){
        el.querySelector('button').addEventListener('click', () => {
            el.classList.toggle('active');
            if(el.classList.contains('active')) this.header.classList.remove('header--white')
            else this.header.classList.add('header--white');
            this.header.querySelector('.header__nav').classList.toggle('active');
            if (this.header.querySelector('.header__fixed').classList.contains('show_search')) this.header.querySelector('.header__fixed').classList.remove('show_search');
        })
    }

    menuMobile(){
        this.header.querySelectorAll('.header__nav__item--submenu').forEach(el => el.querySelector('a').addEventListener('click', e => {
            e.preventDefault();
            el.classList.toggle('active');
            this.glob.slideToggle(el.querySelector('.header__nav__sub'));
        }))
        this.header.querySelectorAll('.header__nav__item--submenu').forEach(item => {
            item.querySelectorAll('ul li').forEach(li => {
                li.addEventListener('click', () => {
                    li.classList.toggle('active');
                    this.glob.slideToggle(li.querySelector('ul'));
                });
            })
        })
        this.header.querySelectorAll('.header__nav__item--small').forEach(el => el.querySelector('a').addEventListener('click', e => {
            if (el.querySelector('ul')) {
                e.preventDefault();
                e.target.classList.toggle('active');
                this.glob.slideToggle(el.querySelector('ul'))
            }
        }))
    }
}

export default Header; 
