
import Global from './global';
import MarkerClusterer from '@googlemaps/markerclustererplus';

class Taxonomy{
    constructor(){
        this.global = new Global();
        this.markers = [];
        this.api = [];
        this.cluster = null;
        this.new_map = null;
        this.infoWindow = new google.maps.InfoWindow();
        this.taxonomy = document.querySelector('.taxonomy_de');
        this.tabs = this.taxonomy ? this.taxonomy.querySelector('.tabs') : false;
        this.element_map = this.taxonomy ? this.taxonomy.querySelector('.boxs .box__map') : false;
        this.btn_ajax = this.taxonomy ? this.taxonomy.querySelector('.box__content') ? this.taxonomy.querySelectorAll('.box__content') : false : false;  
        !this.tabs || this.tabsClickable(this.tabs);
        !this.element_map || this.get_api(this.element_map);
        !this.element_map || this.clickableCheck(this.element_map); 
        !this.btn_ajax || this.btn_ajax.forEach(el => !el.querySelector('.show_next__btn ') || el.querySelector('.show_next__btn ').addEventListener('click', () => this.ajaxGetPosts(el)));
    }


    ajaxGetPosts(el) {
        const http = new XMLHttpRequest(),
            url = AJAXURL;
        let data = {
            action: 'ajax_global',
        };
        data = { ...data, ...el.querySelector('.show_packages__lists').dataset };
        const params = this.global.objectDecapit(data);
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => {
            el.classList.add('loader'); 
            el.querySelector('.show_next__btn').classList.add('active')
        };
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                let posts = JSON.parse(http.response);
                if (posts.post) posts.post.forEach(item => el.querySelector('.show_packages__lists').append(posts.type == 'packages' ? this.post_packages(item) : this.post(item)));
                if (!posts.pagination) el.querySelector('.show_next__btn').classList.add('hidden');
                el.querySelector('.show_packages__lists').dataset.count = Number(el.querySelector('.show_packages__lists').dataset.count) + 9;

                let first = el.querySelector('.show_next h3 .first'),
                    last_number = el.querySelector('.show_next h3 .last'),
                    first_number = Number(first.innerText);
                first.innerText = first_number + Number(posts.count);
                last_number.innerText = posts.all || 0;
                let procent = (Number(first.innerText) / posts.all) * 100 || 0;
                el.querySelector('.show_next__bars span').style.width = procent + '%';

            }
        };
        http.onloadend = () => {
            el.classList.remove('loader')
            el.querySelector('.show_next__btn').classList.remove('active')
        }
        http.send(params);
    }

    post_packages(element) {
        let div = document.createElement('div'),
            prices = '',
            lists = [];
        div.classList.add('show_packages__box');
        
        if (element.price.price != 0) {
            prices = `<span class="price">
                <p>From</p>
                <h4 class="price_regular ${element.price.price_promoto ? 'price_regular--despile' : ''}">£${element.price.price}</h4>
                ${element.price.price_promoto ? `<h4 class="price_promoto">£${element.price.price_promoto}</h4>` : ''}
            </span>`
        }
        if (element.lists) element.lists.forEach(item => lists.push(`<li><a href="${item.link}">${item.title}</a></li>`));
        div.innerHTML = `
            <a class="image" href="${element.link}">
                <img  src="${element.image}" alt="${element.title_image}">
                ${prices}
            </a>
            ${element.category ? `<h4>${element.category.join(', ')}</h4>` : ''}
            <h3><a href="${element.link}">${element.title}</a></h3>
            ${lists && lists.length != 0 ? `<ul>${lists.join('')}</ul>` : ''}
        `;
        return div;
    }

    post(element) {
        let prices = '',
            div = document.createElement('div');
        div.classList.add('query_boxs__box');
        if (element.price && element.price.price != 0) {
            prices = `<span class="price">
                <p>From</p>
                <h4 class="price_regular ${element.price.price_promoto ? 'price_regular--despile' : ''}">£${element.price.price}</h4>
                ${element.price.price_promoto ? `<h4 class="price_promoto">£${element.price.price_promoto}</h4>` : ''}
            </span>`;
            div.classList.add('show_packages__box');
        }
        div.innerHTML = `<a href="${element.link}"><div class="image"><img src="${element.image}" alt="${element.title_image}">${prices}</div><h3>${element.title}</h3></a>${element.categories}`;
        return div;
    }

    tabsClickable(element){
        element.querySelectorAll('li').forEach(item => item.addEventListener('click', e => {
            element.querySelectorAll('li').forEach(ele => ele.classList.remove('active'));
            item.classList.add('active');
            this.taxonomy.querySelectorAll('.boxs .box').forEach(box => {
                if(item.dataset.name === box.dataset.name) box.classList.add('active');
                else box.classList.remove('active');
            });
        }));
    }
    get_api(element){
        let url = element.dataset.id ? window.location.origin + '/wp-json/wp/v2/destinations/' + element.dataset.id : window.location.origin + '/wp-json/wp/v2/destinations_all/';
        fetch(url)
        .then(response => {
            return response.json()
        })
        .then(results => {
            this.api = results;
            this.map(element);
        })   
    }

    map(element){
        this.new_map = new google.maps.Map(element.querySelector('.map_box'), {
                zoom: 8,
            center: this.arrayLocations(element)[0].position,
                styles: this.global.stylesMap()
            });
        this.markers = this.arrayLocations(element).map((location, i) => this.addMarker(location, this.new_map));

        this.cluster = new MarkerClusterer(this.new_map, this.markers, {
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
    }

    addMarker(location, map){
        let marker = new google.maps.Marker({
            position: location.position,
            icon: location.icon,
            title: location.title 
        });

        marker.addListener("click", () => {
            this.infoWindow.close();
            this.infoWindow.setContent(this.popUpMap(location));
            this.infoWindow.open(map, marker);
        });
        return marker;
    }

    arrayLocations(element){
        const api_map = this.api;
        let input_check = element.lastElementChild.querySelectorAll('input'),
            names = [],
            maps = [],
            loco = [];
        input_check.forEach(element => !element.checked || names.push(element.name));
        Object.values(api_map).map((location, i) => {
            location.forEach(e => !names.includes(e.name) || loco.push(e));
        });
        if (typeof loco !== 'undefined' && loco.length > 0) {
            loco.forEach(item => {
                let version = false;
                
                if (item.map) {
                    if (maps)  {
                        for (let i = 0; i < maps.length; i++) {
                            maps[i].position.lat === item.map.lat && maps[i].position.lng === item.map.lng ? version = i : version = false;
                            if(version) break;
                        }
                    }
                    if (version){
                            maps[version].info.push({
                            position: { lat: item.map.lat, lng: item.map.lng },
                            title: item.title,
                            name: item.name,
                            image: item.icon,
                            link: item.link
                        });
                    }
                    else maps.push({
                        position: { lat: item.map.lat, lng: item.map.lng },
                        icon: item.marker,
                        info: [
                            {
                                title: item.title,
                                name: item.name,
                                image: item.image,
                                link: item.link
                            }
                        ]
                    });
                    
                }
            })
        }
        maps.forEach(el => el.info.length > 1 ? el.icon = this.element_map.dataset.icon : null)
        return maps;
    }

    clickableCheck(inputs){
        inputs.querySelectorAll('input').forEach(element => element.addEventListener('click', () => {
            this.markers.forEach(marker => marker.setMap(null));
            this.cluster.clearMarkers();
            this.markers = this.arrayLocations(inputs).map(location => this.addMarker(location, this.new_map));
            this.cluster = new MarkerClusterer(this.new_map, this.markers, {
                imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            });

            
        }))
    }

    popUpMap(element){
        let types = ['packages', 'courses', 'hotels', 'resorts'],
            html = '<div class="popup-map">'; 
        if(element.info){
            types.forEach(type => {
                let valid = true;
                element.info.forEach(item => {
                    if(item.name === type){
                        if(valid){
                            html += `<h2>${item.name}</h2>`;
                            valid = false;
                        }
                        html += `<div class="box__item">
                            <h3>${item.title}</h3>
                            <a href="${item.link}">See more</a>
                        </div>`
                    }
                })

            })
        }
        html += '</div>';
        return html;
    }
}

export default Taxonomy;