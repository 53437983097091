import Global from './global';
import Swiper from 'swiper/js/swiper.js';
import flatpickr from "flatpickr";
import 'fslightbox';
class All{
    constructor(){
        this.global = new Global();
        this.swipers = [];
        this.main = document.getElementById('main_section');
        this.banner_swiper = document.querySelector('.slider__banner') ? document.querySelectorAll('.slider__banner') : false;
        this.tabs = document.querySelector('.packages-single__tab') ? document.querySelectorAll('.packages-single__tab') : false;
        this.maps = document.querySelector('.map') ? document.querySelectorAll('.map') : false;
        this.buttons = document.querySelector('button') ? document.querySelectorAll('button') : false;
        this.btn_show = document.querySelector('.query_boxs__btn-show') ? document.querySelectorAll('.query_boxs__btn-show') : false;
        this.packages_boxs = document.querySelector('.packages__query.show_packages') ? document.querySelectorAll('.packages__query.show_packages') : false;
        this.accord = document.querySelector('.accordeon_f') || false;
        !document.querySelector('.wpcf7-validates-as-date') || document.querySelectorAll('.wpcf7-validates-as-date').forEach(el => flatpickr(el, {
            dateFormat: "Y-m-d",
            "disable": [
                function (date) {
                    // return true to disable
                    return (date.getDay() === 7);

                }
            ],
           
        }));
        

        !this.accord || this.global.s_accordeon(this.accord);

        this.lightbox = new FsLightbox();
        refreshFsLightbox();
        !this.main || this.reloadCls();
        !this.maps || this.maps.forEach(element => this.singleMap(element));
        !this.banner_swiper || this.banner_swiper.forEach(element => this.swiperBanner(element));
        !this.tabs || this.tabs.forEach(element => this.tabsElement(element));        
        !this.buttons || this.buttons.forEach(element => element.innerText === 'Enquire now' || element.innerText === 'Enquire' ? this.scrollForm(element) : element.innerText === 'View Packages' ? this.scrollFromPackages(element) : '');
        !this.btn_show || this.btn_show.forEach(element => this.showElements(element));
        !this.packages_boxs || this.packages_boxs.forEach(element => this.showElements(element.querySelector('.query_boxs__btn-show')));
    }

    showElements(el){
        if(el){
            el.addEventListener('click', e => {
                e.preventDefault();
                let parElement = el.parentElement,
                    hiddenElements = parElement.querySelectorAll('.query_boxs__flex .hidden');
                for (let i = 0; i < hiddenElements.length; i++) {
                    console.log(i);
                    if(i < 3) hiddenElements[i].classList.remove('hidden');
                }
                if(hiddenElements.length <= 3) el.classList.add('hidden');
            })
        }
    }

    scrollFromPackages(el){
        if (!document.querySelector('.packages__query.show_packages')) el.remove();
        el.addEventListener('click', () => {
            if (document.querySelector('.packages__query.show_packages'))
                document.querySelector('.packages__query.show_packages').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        })
    }

    scrollForm(el){
        el.addEventListener('click', () => {
            if (document.querySelector('.form_down'))
                document.querySelector('.form_down').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        })
    }

    reloadCls(){
        if (this.main.classList.contains('hidden__forms')) document.querySelector('.form_down').classList.add('hidden');
        else document.querySelector('.form_down').classList.remove('hidden');

        if (this.main.classList.contains('hidden__newsletter')) document.querySelector('.newsletter').classList.add('hidden');
        else document.querySelector('.newsletter').classList.remove('hidden');

        if (this.main.classList.contains('hidden__faq')) document.querySelector('.accordeon--down') ?  document.querySelector('.accordeon--down').classList.add('hidden') : null;
        else document.querySelector('.accordeon--down').classList.remove('hidden');
        
    }

    tabsElement(element){
        element.querySelectorAll('.packages-single__tab__item').forEach(item => item.addEventListener('click', () => this.activTab(item)));
        element.querySelectorAll('.box__tab__item').forEach(item => item.addEventListener('click', () => this.activeSubTab(item)));
    }

    activeSubTab(element){
        if(!element.classList.contains('active')){
            element.parentElement.querySelector('.active').classList.remove('active');
            element.classList.add('active');
            element.closest('.box').querySelectorAll('.box__content').forEach(item => {
                if(item.dataset.id === element.dataset.id) item.classList.add('active');
                else item.classList.remove('active');
            });
            this.reloadSwiper();
        }
    }

    activTab(element){
        if (!element.classList.contains('active')){
            element.parentElement.querySelector('.active').classList.remove('active');
            element.classList.add('active');
            element.closest('.packages-single__tab').querySelectorAll('.packages-single__boxs .box').forEach(item => {
                if(item.dataset.name === element.dataset.name) item.classList.add('active');
                else item.classList.remove('active');
            });
            this.reloadSwiper();
        }
    }

    reloadSwiper(){
        this.swipers.forEach(element => element.update());
    }

    swiperBanner(element){
        this.swipers.push(new Swiper(element.querySelector('.swiper-container'), {
            slidesPerView: element.dataset.number ? element.dataset.number != 1 ? (element.dataset.number/2) : 1 : 1.2,
            spaceBetween: 5,
            loop:true,
            observer:true,
            centeredSlides: true,
            breakpoints: {
                560: {
                    slidesPerView: element.dataset.number ? element.dataset.number : 2.2,
                },
              
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                activeIndexChange: function () {
                    refreshFsLightbox();
                    element.querySelectorAll('video').forEach(item => { 
                        item.pause(); 
                        item.parentElement.classList.remove("video--active");
                    });
                },
            },
        }));
        element.querySelectorAll('video').forEach(item => item.addEventListener('click', () => {
            if (item.paused){
                item.play();
                item.parentElement.classList.add("video--active");
            }else{
                item.pause();
                item.parentElement.classList.remove("video--active");
            }
        }));
    }

    singleMap(element){
        this.map_g = new google.maps.Map(element, {
            center: { lat: element.dataset.lat*1, lng: element.dataset.lng*1 },
            zoom: element.dataset.zoom*1,
            styles: this.global.stylesMap()
        });
        let marker = new google.maps.Marker({
            position: {
                lat: element.dataset.lat*1,
                lng: element.dataset.lng*1
            },
            map: this.map_g,
            icon: this.iconMarek(element)
        });
    }
    iconMarek(element) {
        if(element.dataset.icon) return { 
            url:element.dataset.icon,
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0)
        }
        else return {
            path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#f56a51',
            fillOpacity: 1,
            anchor: new google.maps.Point(0, 0),
            strokeWeight: 0,
            scale: 0.3,
        };
    }
   
}

export default All;