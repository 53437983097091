import Global from './global';
class Ajax{
    constructor(){
        this.glob = new Global();
        this.single_courses = document.querySelector('.query_boxs') ? document.querySelectorAll('.query_boxs') : '';
        !this.single_courses || this.single_courses.forEach(element => this.ajaxSingleCourses(element));
    }

    ajaxSingleCourses(el){
        !el.lastElementChild || el.lastElementChild.addEventListener('click', e => {
            e.preventDefault();
            if (!el.lastElementChild.classList.contains('query_boxs__btn-show'))
            this.ajaxGetPosts(el);    
        })

    }
    ajaxGetPosts(el){   
        console.log(el.dataset);
        const http = new XMLHttpRequest(),
            url = AJAXURL;
        let data = {
            action: 'ajax_global',
        };
        data = { ...data, ...el.dataset};
        const params = this.glob.objectDecapit(data);
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => el.classList.add('loader');
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                let posts = JSON.parse(http.response);
                if (posts.post) posts.post.forEach(item => el.querySelector('.query_boxs__flex').append(this.post(item)));
                if (!posts.pagination) el.lastElementChild.classList.add('hidden');
                el.dataset.count = Number(el.dataset.count) + 3;
            }
        };
        http.onloadend = () => el.classList.remove('loader');
        http.send(params);
    }

    post(element) {
        let div = document.createElement('div');
        div.classList.add('query_boxs__box');
        div.innerHTML = `<a href="${element.link}"><div class="image"><img src="${element.image}" alt="${element.title_image}"></div><h3>${element.title}</h3></a>${element.categories}`;
        return div;
    }

}

export default Ajax;