import PureCounter from '@srexi/purecounterjs/js/purecounter';
import Choices from 'choices.js';
import Global from './global';
class Bulider{
    
    constructor(){
        const pure = new PureCounter();
        this.glob = new Global();
        this.input_hidden = document.querySelector('input[name=actual-link-page]') ? document.querySelectorAll('input[name=actual-link-page]') : false;
        this.accordeon = document.querySelector('.accordeon_s') ? document.querySelectorAll('.accordeon_s') : false;
        this.grid_count = document.querySelector('.grid_counts') ? document.querySelector('.grid_counts') : false;
        this.archive_cureses = document.querySelector('.archive-curses') ? document.querySelectorAll('.archive-curses') : false;
        this.archive = document.querySelector('.archive_gl') ? document.querySelectorAll('.archive_gl') : false;
        this.search = document.querySelector('.banner__search') ? document.querySelectorAll('.banner__search') : false;
        this.search_box = document.querySelector('.search_page') ? document.querySelector('.search_page') : false;
        !this.accordeon || this.accordeon.forEach(element => this.glob.s_accordeon(element));
        !this.grid_count || new PureCounter();
        !this.archive_cureses || this.archive_cureses.forEach(element => this.archiveCurses(element)); 
        !this.archive || this.archive.forEach(element => this.archiveSection(element));
        !this.search || this.search.forEach(element => this.glob.sectionSearch(element));
        !this.search_box || this.sectionSerachBox(this.search_box);
        !this.input_hidden || this.input_hidden.forEach(element => element.value = window.location.href);
    }

    sectionSerachBox(section){
        let nav = section.querySelectorAll('.search_page__nav li');
        nav.forEach(el => {
            if(el.classList.contains('found')){
                el.querySelector('span').addEventListener('click', () => {
                    nav.forEach(item => item.classList.remove('active'))  
                    let element = el.querySelector('span');
                    element.parentElement.classList.add('active');
                    section.querySelectorAll('.search_page__tabs .tab_box').forEach(item => {
                        item.classList.remove('active');
                        if(element.dataset.name === item.dataset.name){
                            item.classList.add('active');
                        }
                    })

                })

            }
        });
        if (!this.archive_cureses.classList.contains('archive-packages')) {
            section.querySelectorAll('.tab_box').forEach(element => {
                element.querySelector('.show_next__btn ').addEventListener('click', () => this.archiveGlobal(element));
            })
        }
    }

   

    archiveSection(element){
        if (!this.archive_cureses.classList.contains('archive-packages')) {
            let selectes = element.querySelector('select') ? element.querySelectorAll('select') : false;
            !selectes || selectes.forEach(item => {
                new Choices(item, {
                    searchEnabled: false,
                    searchChoices: false,
                    itemSelectText: ''
                });
                item.addEventListener('change', () => this.archiveGlobal(element, true));
            });
        
            element.querySelector('.show_next__btn').addEventListener('click', e => this.archiveGlobal(element));
        }
    }

    archiveGlobal(element, count_val=false){
        let dataset = element.dataset,
            selected = false;
            
        if(count_val) dataset.count = 0;
        if (element.querySelector('.archive_gl__courses')) selected = element.querySelector('.archive_gl__courses').value;
        console.log(selected);
        const http = new XMLHttpRequest(),
            url = AJAXURL;
        let data = {
            action: 'ajax_global',
            selectes: selected,
        };
        data = { ...data, ...dataset };
        const params = this.glob.objectDecapit(data);

        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => {
            element.querySelector('.archive-curses__query__posts').classList.add('loader');
            element.querySelector('.show_next__btn').classList.add('active')
        };
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
                let count = element.dataset.count;
                let posts = JSON.parse(http.response);
                if (count == 0) {
                    element.querySelector('.archive-curses__query__posts').innerHTML = '';
                    element.dataset.count = 0;
                }
                if (posts.post) posts.post.forEach(item => element.querySelector('.archive-curses__query__posts').append(element.dataset.type == 'packages' ? this.post_packages(item) : this.post(item)));
                else element.querySelector('.archive-curses__query__posts').innerHTML = `<h2>${posts.no_found}</h2>`;

                let first = element.querySelector('.show_next h3 .first'),
                    last_number = element.querySelector('.show_next h3 .last'),
                    first_number = Number(first.innerText),
                    ps_count = posts.count === undefined ? 0 : posts.count;

                first.innerText = Number(count) === 0 ? ps_count : first_number + Number(ps_count);
                last_number.innerText = posts.all || 0;
                let procent = (Number(first.innerText) / posts.all) * 100 || 0;
                element.querySelector('.show_next__bars span').style.width = procent + '%';
                if (!posts.pagination) {
                    element.querySelector('.show_next__btn').classList.add('hidden');
                } else {
                    element.querySelector('.show_next__btn').classList.remove('hidden');
                }
                element.dataset.count = Number(element.dataset.count) + 9;
            }
        };
        http.onloadend = () => {
            element.querySelector('.archive-curses__query__posts').classList.remove('loader');
            element.querySelector('.show_next__btn').classList.remove('active')
        };
        http.send(params);

    } 

    archiveCurses(element){
        
        
        let selectes = element.querySelector('select') ? element.querySelectorAll('select') : false;
        !selectes || selectes.forEach(item => {
                new Choices(item, {
                searchEnabled: false,
                searchChoices: false,
                itemSelectText: ''
            });
            if (!element.classList.contains('archive-packages')) {
                item.addEventListener('change', () => this.reloadCurses(element));
            }
        });
        if (!element.classList.contains('archive-packages')) {
            !element.querySelector('.show_next__btn') || element.querySelector('.show_next__btn ').addEventListener('click', () => this.ajaxCurses(element, element.dataset.count, element.classList.contains('archive-packages')));
        }
        
        
    }
    

    reloadCurses(element){
        this.ajaxCurses(element, 0, element.classList.contains('archive-packages'));
    }

    ajaxCurses(element, count, type=false){
        let selected = {};
        !element.querySelector('select') || element.querySelectorAll('select').forEach(item => selected[item.name] = item.value);
        const http = new XMLHttpRequest(),
            url = AJAXURL;
            let data = {
                action: type ? 'ajax_packages' : 'ajax_curses',
                count: count,
            };
            data = {...data,...selected};
            const params = this.glob.objectDecapit(data);

        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onloadstart = () => {
            element.querySelector('.archive-curses__query__posts').classList.add('loader');
            element.querySelector('.show_next__btn').classList.add('active')
        };
        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status == 200) {
               let posts = JSON.parse(http.response);
                if(count == 0){
                    element.querySelector('.archive-curses__query__posts').innerHTML = '';
                    element.dataset.count = 0;
                }
                if (posts.post) posts.post.forEach(item => element.querySelector('.archive-curses__query__posts').append(type ? this.post_packages(item) : this.post(item)));
                else element.querySelector('.archive-curses__query__posts').innerHTML = `<h2>${posts.no_found}</h2>`;

                let first = element.querySelector('.show_next h3 .first'),
                    last_number = element.querySelector('.show_next h3 .last'),
                    first_number = Number(first.innerText),
                    ps_count = posts.count === undefined ? 0 : posts.count;
                    
                first.innerText = Number(count) === 0 ? ps_count : first_number + Number(ps_count);
                last_number.innerText = posts.all || 0;
                let procent = (Number(first.innerText) / posts.all) * 100 || 0;
                element.querySelector('.show_next__bars span').style.width = procent + '%';
                if (!posts.pagination) {
                    element.querySelector('.show_next__btn').classList.add('hidden');
                } else {
                    element.querySelector('.show_next__btn').classList.remove('hidden');
                }
                element.dataset.count = Number(element.dataset.count) + 9;
            }
        };
        http.onloadend = () => {
            element.querySelector('.archive-curses__query__posts').classList.remove('loader');
            element.querySelector('.show_next__btn').classList.remove('active')
        };
        http.send(params);
       
    }
    post_packages(element){
        let div = document.createElement('div'),
            prices = '',
            lists  = [];
        div.classList.add('show_packages__box');

        if(element.price.price != 0){
            prices = `<span class="price">
                <p>From</p>
                <h4 class="price_regular ${element.price.price_promoto ? 'price_regular--despile' : ''}">£${element.price.price}</h4>
                ${element.price.price_promoto ? `<h4 class="price_promoto">£${element.price.price_promoto}</h4>` : ''}
            </span>`
        }
        if(element.lists) element.lists.forEach(item => lists.push(`<li><a href="${item.link}">${item.title}</a></li>`) );
        div.innerHTML = `
            <a class="image" href="${element.link}">
                <img  src="${element.image}" alt="${element.title_image}">
                ${prices}
            </a>
            ${element.category ? `<h4>${element.category.join(', ')}</h4>` : ''}
            <h3><a href="${element.link}">${element.title}</a></h3>
            ${lists && lists.length != 0 ? `<ul>${lists.join('')}</ul>` : ''}
        `;
        return div;
    }

    post(element) {
        let div = document.createElement('div');
        div.classList.add('box');
        div.innerHTML = `<div class="show_packages__box"><a class="image" href="${element.link}"><img src="${element.image}" alt="${element.title_image}"></a><h3><a href="${element.link}">${element.title}</a></h3>${element.categories}</div>`;
        return div;
    }
}

export default Bulider;