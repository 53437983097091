import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';

import './components/animations';
import Header from './components/header';
import Bulider from './components/bulider';
import All from './components/all';
import Archive from './components/archive';
import Taxonomy from './components/taxonomy_de';
import Ajax from './components/ajax';
import Map from './components/map';
import PackagesArchive from './components/package_archive';


const options = {
    containers: ["#container"],
    debugMode: true,
    plugins: [
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin(),
        new SwupScrollPlugin({
            doScrollingRightAway: false,
            animateScroll: false,
            scrollFriction: 0,
            scrollAcceleration: 0
        }),
    ],
}
const swup = new Swup(options);
swup.preloadPages();


function start(){
    let header = document.body.querySelector('.header'),
        main = document.getElementById('main_section');
    header.querySelector('.header__hamburger').classList.remove('active');
    header.querySelector('.header__nav').classList.remove('active');
    
    if (main.dataset.header == 'header--white') header.classList.add('header--white');
    else header.classList.remove('header--white');

    //new Header();
    //document.querySelector('.header__nav').classList.remove('active');
    new All();
    new Bulider();
    new Archive();
    new Taxonomy();
    new Ajax();
    new PackagesArchive();
    //new Map();
}

function init() { 
    !document.querySelector('.wpcf7 > form') || document.querySelectorAll('.wpcf7 > form').forEach(el => {
        wpcf7.init(el);
    });
   start();

}
swup.on('contentReplaced', init);

function unload() {}
swup.on('willReplaceContent', unload);


new Header();
document.addEventListener('load', start(), false);